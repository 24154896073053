<template>
	<v-row>
		<v-col class="text-right" col="12">
			<v-btn color="primary" dark v-on:click="dialog = true" v-if="showAddBtn">
				<v-icon left>{{ icons.mdiPlus }}</v-icon
				>Add New
			</v-btn>
			<v-dialog v-model="dialog" width="650">
				<v-card>
					<v-card-title>{{ !addTransaction.id ? 'Add New Transaction' : 'Edit Transaction' }}</v-card-title>
					<v-card-text>
						<v-container>
							<v-row>
								<v-col cols="12">
									<v-form ref="form" v-model="isValid" lazy-validation>
										<v-row>
											<v-col cols="12" xs="12" md="6">
												<label>Select Customer</label>
												<v-select
													:items="customersList"
													item-text="companyName"
													item-value="code"
													outlined
													dense
													:readonly="addTransaction.id ? true : false"
													v-model="addTransaction.code"
													:rules="nameRules"
													v-on:change="getPayments"
												></v-select>
											</v-col>
											<v-col cols="12" xs="12" md="6">
												<label>Select Invoice</label>
												<v-select
													:items="paymentsList"
													outlined
													dense
													item-text="sellerInvoiceNo"
													item-value="id"
													:readonly="addTransaction.id ? true : false"
													v-model="addTransaction.paymentId"
													:rules="numberRules"
												></v-select>
											</v-col>
										</v-row>
										<v-row>
											<v-col cols="12" xs="12" md="6">
												<label>Transaction Date</label>
												<v-menu
													v-model="transactionDatePopup"
													:close-on-content-click="false"
													transition="scale-transition"
												>
													<template v-slot:activator="{ on, attrs }">
														<v-text-field
															:value="computedDateFormattedMomentjs"
															readonly
															outlined
															dense
															v-bind="attrs"
															v-on="on"
															:rules="dateRules"
														></v-text-field>
													</template>
													<v-date-picker
														v-model="addTransaction.transactionAt"
														@input="transactionDatePopup = false"
													></v-date-picker>
												</v-menu>
											</v-col>
											<v-col cols="12" xs="12" md="6">
												<label>Amount (INR)</label>
												<v-text-field
													v-model="addTransaction.amount.amount"
													type="number"
													outlined
													dense
													placeholder="E.g. 1000"
													:rules="numberRules"
												></v-text-field>
											</v-col>
										</v-row>
										<v-row>
											<v-col offset-md="5" cols="12">
												<v-btn color="primary" v-on:click="saveTransaction" :loading="loading" :disabled="loading">
													Save
												</v-btn>
												&nbsp;&nbsp;
												<v-btn color="light" @click="dialog = false">Cancel</v-btn>
											</v-col>
										</v-row>
									</v-form>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>
				</v-card>
			</v-dialog>
		</v-col>
	</v-row>
</template>

<script>
	import { mdiPlus, mdicalendar } from '@mdi/js'
	import { ref, reactive } from '@vue/composition-api'
	import Util from '@/utils/axios-util.js'
	import { config } from '@/constants/config.js'
	import moment from 'moment'

	export default {
		data() {
			return {
				dialog: false,
				addTransaction: {
					code: '',
					paymentId: '',
					amount: {
						amount: 0,
						currency: 'INR',
					},
					transactionAt: '',
					id: 0,
				},
				isValid: false,
				loading: false,
				nameRules: [
					v => !!v || 'Value is required.',
					v => (v && v.length > 4) || 'Value must contain minimum 3 characters.',
				],
				numberRules: [v => !!v || 'Value is required.'],
				dateRules: [v => !!v || 'Transaction date is required.'],
				transactionDatePopup: false,
				customersList: [],
				paymentsList: [],
				showAddBtn: false,
			}
		},
		setup() {
			return {
				icons: {
					mdiPlus,
					mdicalendar,
				},
			}
		},
		props: {
			obj: {
				default() {
					return {}
				},
				type: Object,
			},
		},
		mounted() {
			let e = this
			e.getCustomers()
			//e.getPayments()
			if (e.obj != {} && e.obj.id) {
				e.addTransaction = Object.assign({}, e.obj)
				if (e.obj.code) e.getPayments()
				e.dialog = true
			} else {
				e.showAddBtn = true
			}
		},
		methods: {
			saveTransaction: function () {
				let e = this
				let isValidated = e.$refs.form.validate()
				if (isValidated) {
					e.loading = true

					let obj = Object.assign({}, e.addTransaction)
					obj.code = ''
					Util.Axios.post(obj.id ? `/payment/transaction/${obj.id}/update` : '/payment/transaction/add-new', obj)
						.then(function (response) {
							if (response.data.succeeded) {
								e.successMessage('Transaction details saved successfully.')
								e.dialog = false
								e.addTransaction = {
									code: '',
									paymentId: '',
									amount: {
										amount: 0,
										currency: 'INR',
									},
									transactionAt: '',
									id: 0,
								}
								e.$emit('transactionDetailsChanged')
							} else {
								e.errorMessage(e.generateErrorMessage(response.errors))
							}
						})
						.catch(function (response) {})
						.finally(function (response) {
							e.loading = false
						})
				}
			},
			getCustomers: function () {
				let e = this
				Util.Axios.get('/customer/get-paged-customers')
					.then(function (response) {
						e.customersList = response.data.data.items
					})
					.catch(function (response) {})
					.finally(function (response) {})
			},
			getPayments: function () {
				let e = this
				Util.Axios.get('/payment/get-paged-payments')
					.then(function (response) {
						let validPayments = response.data.data.items.filter(p => p.customer)
						e.paymentsList =
							validPayments && validPayments.length
								? validPayments.filter(p => p.customer.code == e.addTransaction.code)
								: []
					})
					.catch(function (response) {})
					.finally(function (response) {})
			},
		},
		computed: {
			computedDateFormattedMomentjs() {
				return this.addTransaction.transactionAt
					? moment(this.addTransaction.transactionAt).format(config.dateFormat)
					: ''
			},
		},
	}
</script>
