<template>
	<v-row>
		<v-col class="text-right" col="12">
			<v-btn color="primary" dark v-on:click="dialog = true">
				<v-icon left>{{ icons.mdiPlus }}</v-icon
				>Add New
			</v-btn>
			<v-dialog v-model="dialog" width="650">
				<v-card>
					<v-card-title>{{ !addPayment.id ? 'Add New Payment' : 'Edit Payment' }}</v-card-title>
					<v-card-text>
						<v-container>
							<v-row>
								<v-col cols="12">
									<v-form ref="form" v-model="isValid" lazy-validation>
										<v-row>
											<v-col cols="12" xs="12" md="6">
												<label>Invoice No</label>
												<v-text-field
													v-model="addPayment.sellerInvoiceNo"
													type="text"
													outlined
													:disabled="addPayment.id && addPayment.id != null"
													dense
													placeholder="E.g. ABCD1234124"
													:rules="nameRules"
												></v-text-field>
											</v-col>
											<v-col cols="12" xs="12" md="6">
												<label>Select Customer</label>
												<v-select
													:items="customersList"
													item-text="value"
													item-value="key"
													outlined
													:disabled="addPayment.id && addPayment.id != null"
													dense
													v-model="addPayment.customerId"
													:rules="numberRules"
												></v-select>
											</v-col>
										</v-row>
										<v-row>
											<v-col cols="12" xs="12" md="6">
												<label>Frequency</label>
												<v-select
													:items="['Monthly', 'Weekly']"
													outlined
													dense
													v-model="addPayment.frequency"
													:rules="nameRules"
													v-on:change="calculateTransactionAmount"
												></v-select>
											</v-col>

											<v-col cols="12" xs="12" md="6">
												<label>Start Date</label>
												<v-menu v-model="startDatePopup" :close-on-content-click="false" transition="scale-transition">
													<template v-slot:activator="{ on, attrs }">
														<v-text-field
															:value="computedDateFormattedMomentjs"
															readonly
															outlined
															dense
															v-bind="attrs"
															v-on="on"
															:rules="dateRules"
														></v-text-field>
													</template>
													<v-date-picker v-model="addPayment.startDate" @input="startDatePopup = false"></v-date-picker>
												</v-menu>
											</v-col>
										</v-row>

										<v-row>
											<v-col cols="12" xs="12" md="6">
												<label>Total amount (INR)</label>
												<v-text-field
													v-model="addPayment.metaData.totalAmount.amount"
													type="number"
													outlined
													v-on:change="calculateTransactionAmount"
													dense
													placeholder="E.g. 1000"
													:rules="numberRules"
												></v-text-field>
											</v-col>

											<v-col cols="12" xs="12" md="6">
												<label>No Of Installments</label>
												<v-text-field
													v-model="addPayment.metaData.noOfInstallments"
													type="number"
													outlined
													v-on:change="calculateTransactionAmount"
													dense
													placeholder="E.g. 1,6,9"
													:rules="numberRules"
												></v-text-field>
											</v-col>
										</v-row>
										<v-row>
											<v-col cols="12" xs="12" md="6">
												<label>Installment amount (INR)</label>
												<v-text-field
													v-model="addPayment.amountPerTransaction.amount"
													type="number"
													outlined
													dense
													placeholder="E.g. 1000"
													:rules="numberRules"
												></v-text-field>
											</v-col>
										</v-row>
										<v-row>
											<v-col offset-md="5" cols="12">
												<v-btn color="primary" v-on:click="savePayment" :loading="loading" :disabled="loading">
													Save
												</v-btn>
												&nbsp;&nbsp;
												<v-btn color="light" @click="dialog = false">Cancel</v-btn>
											</v-col>
										</v-row>
									</v-form>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>
				</v-card>
			</v-dialog>
		</v-col>
	</v-row>
</template>

<script>
	import { mdiPlus, mdicalendar } from '@mdi/js'
	import { ref, reactive } from '@vue/composition-api'
	import Util from '@/utils/axios-util.js'
	import { config } from '@/constants/config.js'
	import moment from 'moment'

	export default {
		data() {
			return {
				dialog: false,
				addPayment: {
					sellerInvoiceNo: '',
					customerId: '',
					amountPerTransaction: {
						amount: '',
						currency: 'INR',
					},
					frequency: '',
					startDate: '',
					metaData: {
						totalAmount: {
							amount: '',
							currency: 'INR',
						},
						noOfInstallments: 0,
					},
				},
				isValid: false,
				loading: false,
				nameRules: [
					v => !!v || 'Value is required.',
					v => (v && v.length > 4) || 'Value must contain minimum 3 characters.',
				],
				numberRules: [v => !!v || 'Value is required.'],
				dateRules: [v => !!v || 'Start Date is required.'],
				startDatePopup: false,
				customersList: [],
			}
		},
		setup() {
			return {
				icons: {
					mdiPlus,
					mdicalendar,
				},
			}
		},
		props: {
			obj: {
				default() {
					return {}
				},
				type: Object,
			},
		},
		mounted() {
			let e = this
			e.getCustomers()
			if (e.obj != {} && e.obj.id) {
				e.addPayment = Object.assign({}, e.obj)
				e.dialog = true
			}
		},
		methods: {
			savePayment: function () {
				let e = this
				let isValidated = e.$refs.form.validate()
				if (isValidated) {
					e.loading = true
					Util.Axios.post(e.addPayment.id ? `/payment/${e.addPayment.id}/update` : '/payment/add-new', e.addPayment)
						.then(function (response) {
							if (response.data.succeeded) {
								e.successMessage('Payment details saved successfully.')
								e.dialog = false
								e.addPayment = {
									sellerInvoiceNo: '',
									customerId: '',
									amountPerTransaction: {
										amount: '',
										currency: 'INR',
									},
									frequency: '',
									startDate: '',
									metaData: {
										totalAmount: {
											amount: '',
											currency: 'INR',
										},
										noOfInstallments: 0,
									},
								}
								e.$emit('paymentDetailsChanged')
							} else {
								e.errorMessage(e.generateErrorMessage(response.errors))
							}
						})
						.catch(function (response) {})
						.finally(function (response) {
							e.loading = false
						})
				}
			},
			getCustomers: function () {
				let e = this
				Util.Axios.get('/customer/get-customers-ddl')
					.then(function (response) {
						e.customersList = response.data.data.items
					})
					.catch(function (response) {})
					.finally(function (response) {})
			},
			calculateTransactionAmount: function () {
				let e = this
				let noOfInstallments = parseInt(e.addPayment.metaData.noOfInstallments)
				let totalAmount = e.addPayment.metaData.totalAmount.amount
				if (noOfInstallments && totalAmount) {
					e.addPayment.amountPerTransaction.amount = parseFloat(totalAmount / noOfInstallments)
				}
			},
		},
		computed: {
			computedDateFormattedMomentjs() {
				return this.addPayment.startDate ? moment(this.addPayment.startDate).format(config.dateFormat) : ''
			},
		},
	}
</script>
