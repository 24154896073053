<template>
  <v-card id="transactions">
    <v-app-bar flat>
      <v-toolbar-title class="text-h6 pl-0">
        Transactions (Invoice: {{ sellerInvoiceNo ? sellerInvoiceNo : 'All' }})
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-if="transactionsList && transactionsList.length" title="Export">
            <v-list-item-title>
              <download-excel
                v-if="transactionsList && transactionsList.length"
                :key="ExcelDownload.key"
                class="btn btn-primary"
                :data="transactionsList"
                :before-generate="startDownload"
                :before-finish="finishDownload"
                :fields="ExcelDownload.fields"
                :name="`tenweeks_transactions_export(Invoice: ${sellerInvoiceNo ? sellerInvoiceNo : 'All'}).xls`"
              >
                <v-icon>{{ icons.mdiFileExportOutline }} </v-icon>
                Download Excel
              </download-excel>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-container>
      <v-row>
        <v-col cols="12" xs="12" md="3">
          <v-select
            v-model="filters.statuses"
            :items="['Not Issued', 'Issued', 'Cancelled', 'Passed', 'Failed']"
            placeholder="Transaction Status"
            v-on:change="getTransactions"
            multiple
            dense
            chips
            clearable
          ></v-select>
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-select
            v-model="filters.customerIds"
            :items="transactionsList"
            item-text="customer.companyName"
            item-value="customer.id"
            placeholder="Company"
            v-on:change="getTransactions"
            multiple
            dense
            chips
            clearable
          ></v-select>
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-select
            v-model="filters.sellerInvoiceNo"
            :items="paymentsList"
            item-text="sellerInvoiceNo"
            item-value="sellerInvoiceNo"
            placeholder="Invoice No"
            dense
            clearable
            v-on:change="getTransactions"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :headers="headers"
      :items="transactionsList"
      item-key="full_name"
      :key="dataTableKey"
      class="table-rounded"
      dense
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <!-- name -->
      <!-- <template #[`item.full_name`]="{ item }">
				<div class="d-flex flex-column">
					<span class="d-block font-weight-semibold text--primary text-truncate">{{ item.full_name }}</span>
					<small>{{ item.post }}</small>
				</div>
			</template>
			<template #[`item.transaction_no`]="{ item }">
				{{ item.transaction_no }} / {{ item.total_transaction }}
			</template> -->
      <template #[`item.transactionAt`]="{ item }">
        {{ item.transactionAt | format_date }}
      </template>

      <template #[`item.payment.metaData.totalAmount.amount`]="{ item }">
        {{ item.payment.metaData.totalAmount.amount | format_money(item.payment.metaData.totalAmount.currency) }}
      </template>

      <template #[`item.amount.amount`]="{ item }">
        {{ item.amount.amount | format_money(item.amount.currency) }}
      </template>
      <template #[`item.issuedAt`]="{ item }">
        {{ item.issuedAt | format_utc_date_local_timezone }}
      </template>
      <!-- status -->
      <template #[`item.status`]="{ item }">
        <v-chip
          small
          :color="
            item.status == 'Passed'
              ? 'success'
              : item.status == 'Cancelled' || item.status == 'Failed'
              ? 'error'
              : item.status == 'Issued'
              ? 'warning'
              : ''
          "
          class="font-weight-medium"
        >
          {{ item.status }}
        </v-chip>
      </template>
      <template #[`item.action`]="{ item }">
        <v-btn-toggle>
          <v-btn
            x-small
            v-on:click="editTransaction({ id: item.id, code: item.customer.code })"
            title="Edit Transaction"
          >
            <v-icon>{{ icons.mdiCircleEditOutline }}</v-icon>
          </v-btn>
          <v-btn x-small v-on:click="deleteTransactionConfirmation(item.id)" title="Delete Transacton">
            <v-icon>{{ icons.mdiDeleteCircleOutline }}</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            :disabled="loading || isFutureDate(item.transactionAt)"
            v-if="item.status != 'Passed'"
            small
            outlined
            v-on:click="capturePayment(item.id)"
            title="Capture Payment"
            ><v-icon>{{ icons.mdiCash }}</v-icon>
          </v-btn>
        </v-btn-toggle>
      </template>
    </v-data-table>

    <v-dialog v-model="deleteDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Are you sure? </v-card-title>

        <v-card-text v-if="deleteObj.transactionAt && deleteObj.amount.amount">
          Do you want to delete transaction
          <b>{{ deleteObj.amount.amount | format_money(deleteObj.amount.currency) }}</b> which is dated on
          <b>{{ deleteObj.transactionAt | format_date }}</b
          >.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn @click="deleteDialog = false"> Cancel </v-btn>

          <v-btn color="error" :loading="deleteLoading" :disabled="deleteLoading" v-on:click="deleteTransaction">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiCircleEditOutline, mdiDeleteCircleOutline, mdiFileExportOutline, mdiCash } from '@mdi/js'
import Util from '@/utils/axios-util.js'
import { ref, reactive } from '@vue/composition-api'
import moment from 'moment'
import Vue from 'vue'

export default {
  props: {
    sellerInvoiceNo: { default: '', type: String },
  },
  setup() {
    const loading = ref(false)
    const deleteDialog = ref(false)
    const deleteLoading = ref(false)
    const dataTableKey = ref('sjdfojdso')
    const transactionsList = ref([])
    const paymentsList = ref([])
    const deleteObj = reactive({})
    const filters = reactive({
      sellerInvoiceNo: '',
      statuses: [],
      customerIds: [],
    })
    const ExcelDownload = reactive({
      data: [],
      loading: false,
      columnNames: {},
      key: 'asdkpaodks',
      fields: {
        Company: 'customer.companyName',
        'Invoice No': 'payment.sellerInvoiceNo',
        'Transaction Date': {
          field: 'transactionAt',
          callback: value => {
            return Vue.filter('format_date')(value)
          },
        },
        'Total Amount': 'payment.metaData.totalAmount.amount',
        'Installment Amount': 'amount.amount',
        'No. of attempts': 'captureAttemptCount',
        'Payment Issued Date': {
          field: 'issuedAt',
          callback: value => {
            return Vue.filter('format_date')(value)
          },
        },
        Status: 'status',
      },
    })
    return {
      headers: [
        { text: 'Company', value: 'customer.companyName' },
        { text: 'Invoice No', value: 'payment.sellerInvoiceNo' },
        { text: 'Transaction Date', value: 'transactionAt' },
        { text: 'Total Amount', value: 'payment.metaData.totalAmount.amount' },
        { text: 'Installment Amount', value: 'amount.amount' },
        { text: 'No. of attempts', value: 'captureAttemptCount' },
        { text: 'Payment Issued Date', value: 'issuedAt' },
        { text: 'Status', value: 'status' },
        { text: '', value: 'action' },
      ],
      transactionsList,
      paymentsList,
      loading,
      ExcelDownload,
      // icons
      icons: {
        mdiDotsVertical,
        mdiCash,
        mdiCircleEditOutline,
        mdiDeleteCircleOutline,
        mdiFileExportOutline,
      },
      dataTableKey,
      filters,
      deleteDialog,
      deleteObj,
      deleteLoading,
    }
  },
  mounted() {
    let e = this
    //e.getData()
    e.getPayments()
    e.filters.sellerInvoiceNo = e.sellerInvoiceNo
    e.getTransactions()
  },
  methods: {
    isFutureDate: function(date) {
      var a = moment()
      var b = moment(date)
      return a.diff(b) < 0
    },
    getTransactions: function() {
      let e = this
      e.loading = true
      Util.Axios.get('/Payment/get-paged-transactions', { params: e.filters })
        .then(function(response) {
          e.transactionsList = Object.assign([], response.data.data.items)
          e.dataTableKey = e.GenerateRandomKey(5)
        })
        .catch(function(response) {})
        .finally(function(response) {
          e.loading = false
        })
    },
    capturePayment: function(id) {
      let e = this
      if (id) {
        e.loading = true
        Util.Axios.post(`/Payment/transaction/${id}/capture-payment`, {})
          .then(function(response) {
            if (response.data.succeeded) {
              e.successMessage('Transaction captured successfully.')
              e.getTransactions()
            } else {
              e.errorMessage(e.generateErrorMessage(response.errors))
            }
          })
          .catch(function(response) {})
          .finally(function(response) {
            e.loading = false
          })
      }
    },
    addPayment: function(data) {
      let e = this
      e.$emit('addPayment', data)
    },
    getPayments: function() {
      let e = this
      e.loading = true
      Util.Axios.get('/payment/get-paged-payments')
        .then(function(response) {
          e.paymentsList = response.data.data.items
        })
        .catch(function(response) {})
        .finally(function(response) {
          e.loading = false
        })
    },
    editTransaction: function(obj) {
      this.$emit('editTransaction', obj)
    },
    deleteTransaction: function() {
      let e = this
      if (e.deleteObj.id) {
        e.deleteLoading = true
        Util.Axios.delete(`/Payment/transaction/${e.deleteObj.id}/delete`)
          .then(function(response) {
            if (response.data.succeeded) {
              e.successMessage('Transaction Deleted successfully.')
              e.getTransactions()
              e.deleteDialog = false
            } else {
              e.errorMessage(e.generateErrorMessage(response.data.errors))
            }
          })
          .catch(function(response) {})
          .finally(function(response) {
            e.deleteLoading = false
          })
      } else {
        e.errorMessage('Invalid transaction selected.')
      }
    },
    deleteTransactionConfirmation: function(id) {
      let e = this
      if (id) {
        e.deleteObj = e.transactionsList.filter(p => p.id == id)[0]
        e.deleteDialog = true
      } else {
        e.errorMessage('Invalid transaction selected.')
      }
    },
    startDownload() {
      let e = this
      e.ExcelDownload.loading = true
    },
    finishDownload() {
      let e = this
      e.ExcelDownload.loading = false
    },
  },
}
</script>
