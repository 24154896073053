<template>
  <v-row>
    <v-col cols="12">
      <AddPaymentForm @paymentDetailsChanged="updatePaymentsDtKey" />
    </v-col>
    <v-col cols="12">
      <datatable
        :key="paymentDtKey"
        @editPayment="editPayment"
        @filterTransactions="transactionsFilterApplied"
      ></datatable>
    </v-col>
    <component
      v-bind:is="editComponent"
      @paymentDetailsChanged="updatePaymentsDtKey"
      @transactionDetailsChanged="updateTransactionDtKey"
      v-if="editComponent && editObj != {}"
      :obj="editObj"
    ></component>
    <v-col cols="12">
      <AddTransactionForm :sellerInvoiceNo="sellerInvoiceNo" @transactionDetailsChanged="updateTransactionDtKey" />
    </v-col>
    <v-col cols="12">
      <TransactionsDatatable
        :key="transactionDtKey"
        @editTransaction="editTransaction"
        :sellerInvoiceNo="sellerInvoiceNo"
      ></TransactionsDatatable>
    </v-col>
    <v-col cols="12">
      <UnsettledAmount></UnsettledAmount>
    </v-col>
  </v-row>
</template>

<script>
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'

import Datatable from './Datatable'
import TransactionsDatatable from '@/views/transaction/Datatable'
import AddPaymentForm from './AddPaymentForm'
import AddTransactionForm from '@/views/transaction/AddTransactionForm'
import UnsettledAmount from '@/views/transaction/UnsettledAmount'
import { ref, reactive } from '@vue/composition-api'
import Util from '@/utils/axios-util.js'
import { config } from '@/constants/config.js'
import moment from 'moment'

export default {
  components: {
    Datatable,
    TransactionsDatatable,
    AddPaymentForm,
    AddTransactionForm,
    UnsettledAmount,
  },
  setup() {
    const paymentDtKey = ''
    const transactionDtKey = ''
    const sellerInvoiceNo = ''
    let editObj = reactive({})
    const editComponent = ref('')

    return {
      paymentDtKey,
      transactionDtKey,
      sellerInvoiceNo,
      editObj,
      editComponent,
    }
  },
  methods: {
    updatePaymentsDtKey: function() {
      let e = this
      e.paymentDtKey = e.GenerateRandomKey(4)
    },
    updateTransactionDtKey: function() {
      let e = this
      e.transactionDtKey = e.GenerateRandomKey(4)
    },
    transactionsFilterApplied: function(sellerInvoiceNo) {
      let e = this
      e.sellerInvoiceNo = sellerInvoiceNo
      e.transactionDtKey = e.GenerateRandomKey(4)
      document.getElementById('transactions').scrollIntoView()
      //e.paymentDtKey = e.GenerateRandomKey(4)
    },
    editPayment: function(id) {
      let e = this
      if (!id) {
        e.errorMessage('Invalid payment selected.')
        return false
      }
      e.editComponent = ''
      Util.Axios.get(`/payment/${id}/get-details`)
        .then(function(response) {
          if (response.data.succeeded) {
            e.editObj = response.data.data
            e.editObj.startDate = moment(response.data.data.startDate).format(config.dateValueFormat)
            e.editComponent = 'AddPaymentForm'
            e.editComponentKey = e.GenerateRandomKey(5)
          } else e.errorMessage('Something went wrong!')
        })
        .catch(function(response) {})
        .finally(function(response) {})
    },
    editTransaction: function(obj) {
      let e = this
      if (!obj.id) {
        e.errorMessage('Invalid transaction selected.')
        return false
      }
      e.editComponent = ''
      Util.Axios.get(`/payment/transaction/${obj.id}/get-details`)
        .then(function(response) {
          if (response.data.succeeded) {
            e.editObj = reactive(response.data.data)
            e.editObj.code = obj.code
            e.editObj.paymentId = response.data.data.payment.id
            e.editObj.transactionAt = moment(response.data.data.transactionAt).format(config.dateValueFormat)
            e.editComponent = 'AddTransactionForm'
            e.editComponentKey = e.GenerateRandomKey(5)
          } else e.errorMessage('Something went wrong!')
        })
        .catch(function(response) {})
        .finally(function(response) {})
    },
  },
}
</script>
